import { Breadcrumbs, Button, Container, Grid, Link, makeStyles, Tab, Tabs, TextField, Theme, Typography } from '@material-ui/core';
import { Page, PaperCustom, StandardConfirmationDialog } from 'components';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { format, startOfMonth } from 'date-fns';
import { GREEN, WHITE } from 'constants/colors';
import SearchInput from 'components/SearchInput';
import { AddBox } from '@material-ui/icons';
import PaginationCustom from 'components/PaginationCustom';
import useRouter from 'hooks/useRouter';
import TransactionTable from 'pages/TransactionJurnalPage/components/TransactionTable';
import NumberFormat from 'react-number-format';

import axios, { CancelTokenSource } from 'axios';
import { ACCOUNT_CATEGORY_BASE_URL, BALANCE_SHEET_BASE_URL, INCOME_STATEMENT_BASE_URL, CLOSE_BOOK_BASE_URL } from 'constants/url';
import { CreateIncomeStatementContent } from 'pages/IncomeStatementPage/components/CreatePage/components';
import FlexBox from 'components/FlexBox';
import { CreateBalanceSheetContent } from 'pages/BalanceSheetPage/components/CreatePage/components';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },
  active: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px'
  },
  breadCrumb: {
    marginTop: '3px'
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  dialogContent: {
    margin: '0 auto',
    padding: '8px',
    overflow: 'auto'
  },
  datePicker: {
    width: 250
  },
  submit: {
    color: WHITE,
    backgroundColor: GREEN
  }
});
const dummyAccountCategory: AccountCategoryModel = {
  id: 0,
  name: '',
  category: '',
  number: 0,
  subAccount: 0,
  isEditable: true,
  isActive: true,
  subItemAccount: []
};
export type TypeOperasionalResource = {
  isLoading: boolean;
  data: any[];
};
const dummyData = {
  isLoading: true,
  data: []
};

const dummyIncomeStatement: IncomeStatement = {
  id: 0,
  totalSales: 0,
  salesReturnBill: 0,
  salesReturn: 0,
  salesDiscount: 0,
  netRevenue: 0,
  totalPurchase: 0,
  purchaseLogisticCost: 0,
  purchaseInvoiceDiscount: 0,
  purchaseReturn: 0,
  receivablesEmployees: 0,
  purchaseInvoicePiece: 0,
  invoicePiece: 0,
  totalPurchaseCost: 0,
  beginningInventory: 0,
  endingInventory: 0,
  costOfGoodsSold: 0,
  totalCost: 0,
  cost: 0,
  otherExpenses: 0,
  grossProfit: 0,
  profitLoss: 0,
  otherIncome: 0,
  totalIncome: 0,
  startDate: '',
  endDate: '',
  isDeleted: false,
  createdAt: ''
};

const dummyBalanceSheet: BalanceSheet = {
  cash: 0,
  briBank: 0,
  mandiriBank: 0,
  bcaBank: 0,
  giro: 0,
  totalCash: 0,
  accountsReceivable: 0,
  purchaseInvoiceReturn: 0,
  InvoiceReturnBill: 0,
  purchaseAdvance: 0,
  endingInventory: 0,
  totalAccountsReceivable: 0,
  TotalCurrentAssets: 0,
  vehicles: 0,
  buildings: 0,
  equipmentAndMachinery: 0,
  totalAsetTetap: 0,
  totalActiva: 0,
  accountsPayableToSuppliers: 0,
  salesPayment: 0,
  totalShortLiabilities: 0,
  bankLoan: 0,
  totalLiabilities: 0,
  ownerCapital: 0,
  retainedEarnings: 0,
  salesDeposit: 0,
  purchaseDeposit: 0,
  currentPeriodNetProfit: 0,
  totalEkuitas: 0,
  totalPassiva: 0,
  bank: [],
  asset: [],
  totalModal: 0,
  modal: [],
  loan: [],
  totalLoan: 0,
  bankLoanResult: [],
  receivablesEmployees: 0,
  invoicePaymentGiroUnpaid: 0,
  purchaseInvoicePaymentGiroUnpaid: 0,
  totalPrice: 0,
  AccountsReceivable: 0,
  salesReturn: 0,
  salesReturnBill: 0,
  inOther: 0
};

export type TypeCloseBook = {
  isLoading: boolean;
  data: CloseBookModel[];
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};
export type TypeJournalTransactions = {
  isLoading: boolean;
  data: transactionJurnal[];
};
const CloseBookCreatePage = () => {
  const { history } = useRouter();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [accountCategories, setAccountCategories] = useState<AccountCategoryModel[]>([dummyAccountCategory]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [transaksi, setTransaksi] = useState<string>('');
  const [akun, setAkun] = useState<string>('');
  const [tab, setTab] = React.useState<number>(0);
  const [journalTransactions, setJournalTransactions] = useState<TypeJournalTransactions>({
    isLoading: false,
    data: []
  });
  const [otherIncomeCategoryExpand, setOtherIncomeCategoryExpand] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [selectedStartDate, setSelectedStartDate] = useState<string>(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<string>(endDate);
  const [startErrorMessage, setStartErrorMessage] = useState<string>('');
  const [endErrorMessage, setEndErrorMessage] = useState<string>('');
  const [isPackage, setPackage] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [omset, setOmset] = useState<boolean>(false);
  const [purchase, setPurchase] = useState<boolean>(false);
  const [hpp, setHpp] = useState<boolean>(false);
  const [costOperational, setCostOperational] = useState<boolean>(false);
  const [incomeStatement, setIncomeStatement] = useState<TData<IncomeStatement>>({
    isLoading: dummyData.isLoading,
    data: dummyIncomeStatement
  });

  const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [otherExpensesCategory, setOtherExpensesCategory] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [otherExpensesCategoryExpand, setOtherExpensesCategoryExpand] = useState<boolean>(false);
  const [otherIncomeCategory, setItherIncomeCategory] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [balanceSheet, setBalanceSheet] = useState<TData<BalanceSheet>>({
    isLoading: false,
    data: dummyBalanceSheet
  });

  const [closeBook, setCloseBook] = useState<CloseBookModel>();

  const [kas, setKas] = useState<boolean>(false);
  const [bankLoan, setBankLoan] = useState<boolean>(false);
  const [tradeReceivables, setTradeReceivables] = useState<boolean>(false);

  const fetchDataLast = useCallback(async () => {
    try {
      const { data } = await axios.get(`${CLOSE_BOOK_BASE_URL}/last`, {
        cancelToken
      });

      console.log('data -->', data);

      setCloseBook(data);
      setStartDate(format(new Date(data.endDate), 'yyyy-MM-dd'));
    } catch (error) {
      console.log(error);
    }
  }, []);

  console.log('closeBook --->', closeBook);

  const getFormBalanceSheet = async () => {
    setBalanceSheet(prev => ({ ...prev, isLoading: true }));
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setBalanceSheet(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

      return params;
    };

    try {
      const { data } = await axios.get(`${BALANCE_SHEET_BASE_URL}/form?${getQueryParams()}`, {
        cancelToken
      });
      console.log(data);
      setBalanceSheet(prev => ({ ...prev, isLoading: false, data: data }));
    } catch (error) {
      console.log(error);
    }
  };

  const getFormIncomeStatement = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIncomeStatement(prev => ({ ...prev, isLoading: true }));

    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

      return params;
    };

    try {
      console.log('get form');
      const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/form?${getQueryParams()}`, {
        cancelToken
      });
      console.log(data);
      setIncomeStatement(prev => ({ ...prev, isLoading: false, data: data }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartDateChange = (selectedStartDate: Date | null) => {
    if (selectedStartDate) {
      setStartDate(format(selectedStartDate, 'yyyy-MM-dd'));
    }

    // validation(selectedStartDate, new Date(endDate));
  };

  const handleEndDateChange = (selectedEndDate: Date | null) => {
    if (selectedEndDate) {
      setEndDate(format(selectedEndDate, 'yyyy-MM-dd'));
    }

    //validation(new Date(selectedStartDate), selectedEndDate);
  };

  const handleSubmit = async () => {
    const dataFinal = {
      startDate,
      endDate
    };
    setIsSubmit(true);
    try {
      const { data } = await axios.post(CLOSE_BOOK_BASE_URL, dataFinal);
      // reFetchData();
      // reMounted();
      handleSnackBar(true, 'success', 'Tutup buku berhasil ditambahkan.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Tutup buku gagal ditambahkan.');
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' = 'delete', type: string) => {};
  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
    setPackage(newValue === 1);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    // fetchCost();
    // fetchOtherCost();
    fetchDataLast();
    getFormIncomeStatement();
    getFormBalanceSheet();
    // otherIncome();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchDataLast();
  }, []);

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push('/tutup-buku');
  };

  console.log('closeBook length->', closeBook?.id);

  return (
    <Page title='Tutup Buku'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Tambah Tutup buku </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
        </Grid>
        <PaperCustom>
          <Grid container spacing={2}>
            <Grid item xs={12} container justify='space-between' alignItems='center' spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label='Dari Tanggal'
                  value={startDate}
                  disabled={closeBook && closeBook.id > 0}
                  onChange={e => handleStartDateChange(new Date(e.target.value))}
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='Sampai Tanggal'
                  //className={classes.datePicker}
                  value={endDate}
                  onChange={e => handleEndDateChange(new Date(e.target.value))}
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container justify='space-between' alignItems='center' style={{ marginTop: '10px' }} spacing={2}>
              <Grid container className={classes.tabPanel}>
                <Tabs value={tab} indicatorColor='primary' textColor='primary' onChange={handleTabs} aria-label='tab-produk'>
                  <Tab label='Laba Rugi' />
                  <Tab label='Neraca' />
                </Tabs>
              </Grid>
            </Grid>
            {tab === 0 ? (
              <FlexBox item xs={12} container rowGap={1}>
                <Suspense
                  fallback={
                    <Grid xs={12} container justify='center' alignItems='center'>
                      <Typography variant='h6'>Sedang Membuka...</Typography>
                    </Grid>
                  }
                >
                  <CreateIncomeStatementContent
                    incomeStatement={incomeStatement}
                    omset={omset}
                    setOmset={setOmset}
                    hpp={hpp}
                    setHpp={setHpp}
                    purchase={purchase}
                    setPurchase={setPurchase}
                    costOperational={costOperational}
                    setCostOperational={setCostOperational}
                    operasionalResource={operasionalResource}
                    otherIncomeCategory={otherIncomeCategory}
                    setOtherIncomeCategoryExpand={setOtherIncomeCategoryExpand}
                    otherIncomeCategoryExpand={otherIncomeCategoryExpand}
                    otherExpensesCategory={otherExpensesCategory}
                    setOtherExpensesCategoryExpand={setOtherExpensesCategoryExpand}
                    otherExpensesCategoryExpand={otherExpensesCategoryExpand}
                  />
                </Suspense>
              </FlexBox>
            ) : (
              <FlexBox item xs={12} container rowGap={1}>
                <CreateBalanceSheetContent
                  balanceSheet={balanceSheet}
                  kas={kas}
                  setKas={setKas}
                  tradeReceivables={tradeReceivables}
                  setTradeReceivables={setTradeReceivables}
                  omset={omset}
                  setOmset={setOmset}
                  bankLoan={bankLoan}
                  setBankLoan={setBankLoan}
                  hpp={hpp}
                  setHpp={setHpp}
                  purchase={purchase}
                  setPurchase={setPurchase}
                  costOperational={costOperational}
                  setCostOperational={setCostOperational}
                  operasionalResource={operasionalResource}
                />
              </FlexBox>
            )}
          </Grid>
          <Grid
            container
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            justify='flex-start'
            style={{ marginTop: '30px', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant='h6' style={{ marginRight: '30px', flex: 1 }}>
              {incomeStatement.data.profitLoss < 0 ? 'TOTAL RUGI BERSIH :' : '  TOTAL LABA BERSIH :'}
            </Typography>
            <Typography variant='h6'>
              {incomeStatement.data.profitLoss < 0 ? (
                <NumberFormat
                  value={Math.abs(incomeStatement.data.profitLoss)}
                  prefix={'(Rp '}
                  suffix={')'}
                  thousandSeparator={true}
                  displayType='text'
                />
              ) : (
                <NumberFormat value={incomeStatement.data.profitLoss} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>
          <Grid
            container
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            justify='flex-start'
            style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant='h6' style={{ marginRight: '30px', flex: 1 }}>
              TOTAL PENJUALAN BERSIH :
            </Typography>
            <Typography variant='h6'>
              {incomeStatement.data.netRevenue < 0 ? (
                <NumberFormat
                  value={Math.abs(incomeStatement.data.netRevenue)}
                  prefix={'(Rp '}
                  suffix={')'}
                  thousandSeparator={true}
                  displayType='text'
                />
              ) : (
                <NumberFormat value={incomeStatement.data.netRevenue} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>

          <Grid
            container
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            justify='flex-start'
            style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant='h6' style={{ marginRight: '30px', flex: 1 }}>
              TOTAL BIAYA :
            </Typography>
            <Typography variant='h6'>
              {incomeStatement.data.cost < 0 ? (
                <NumberFormat value={Math.abs(incomeStatement.data.cost)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
              ) : (
                <NumberFormat value={incomeStatement.data.cost} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>

          <Grid
            container
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            justify='flex-start'
            style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant='h6' style={{ marginRight: '30px', flex: 1 }}>
              TOTAL ASET TETAP :
            </Typography>
            <Typography variant='h6'>
              {balanceSheet.data.totalAsetTetap < 0 ? (
                <NumberFormat
                  value={Math.abs(balanceSheet.data.totalAsetTetap)}
                  prefix={'(Rp '}
                  suffix={')'}
                  thousandSeparator={true}
                  displayType='text'
                />
              ) : (
                <NumberFormat value={balanceSheet.data.totalAsetTetap} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>

          <Grid
            container
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            justify='flex-start'
            style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant='h6' style={{ marginRight: '30px', flex: 1 }}>
              TOTAL ASET LANCAR :
            </Typography>
            <Typography variant='h6'>
              {balanceSheet.data.TotalCurrentAssets < 0 ? (
                <NumberFormat
                  value={Math.abs(balanceSheet.data.TotalCurrentAssets)}
                  prefix={'(Rp '}
                  suffix={')'}
                  thousandSeparator={true}
                  displayType='text'
                />
              ) : (
                <NumberFormat value={balanceSheet.data.TotalCurrentAssets} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>

          <Grid
            container
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            justify='flex-start'
            style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant='h6' style={{ marginRight: '30px', flex: 1 }}>
              TOTAL LIABILITAS :
            </Typography>
            <Typography variant='h6'>
              {balanceSheet.data.totalLiabilities < 0 ? (
                <NumberFormat
                  value={Math.abs(balanceSheet.data.totalLiabilities)}
                  prefix={'(Rp '}
                  suffix={')'}
                  thousandSeparator={true}
                  displayType='text'
                />
              ) : (
                <NumberFormat value={balanceSheet.data.totalLiabilities} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>

          <Grid container item lg={6} md={6} sm={6} xs={6} justify='flex-start' style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography variant='h6' style={{ marginRight: '30px', flex: 1 }}>
              TOTAL EKUITAS :
            </Typography>
            <Typography variant='h6'>
              {balanceSheet.data.totalPassiva < 0 ? (
                <NumberFormat
                  value={Math.abs(balanceSheet.data.totalPassiva)}
                  prefix={'(Rp '}
                  suffix={')'}
                  thousandSeparator={true}
                  displayType='text'
                />
              ) : (
                <NumberFormat value={balanceSheet.data.totalPassiva} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary'>
              {/* onClick={handleCloseCreates}> */}
              Batal
            </Button>

            <Button onClick={handleSubmit}>Simpan</Button>
          </Grid>

          <StandardConfirmationDialog
            variant={snackbarVariant}
            titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
            message={message}
            open={openSnackbar}
            handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
            onConfirm={handleConfirmSnackbar}
            noCancelButton={true}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CloseBookCreatePage;
