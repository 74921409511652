import React, { FC } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import BodyRow from './components/BodyRow';

interface Props {
  handleIndividualCheck: (item: GiroModalModel) => void;
  giro: GiroModalModel[];
}

const GiroTable: FC<Props> = props => {
  const { handleIndividualCheck, giro } = props;

  console.log('giro --->', giro);

  const handleDelete = (selectedGiro: GiroModalModel) => {
    handleIndividualCheck(selectedGiro);
  };
  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Tanggal Jatuh Tempo</TableCell>
            <TableCell align='center'>Bank Penerbit</TableCell>
            <TableCell align='center'>Nomor Giro</TableCell>
            <TableCell align='center'>Nominal Giro</TableCell>
            <TableCell align='center'>Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {giro.length > 0 &&
            giro.map((giro, index) => (
              <BodyRow
                key={giro.giroNumber ?? index}
                giro={giro}
                index={index}
                isLoading={false}
                isUpdate={false}
                totalPay={0}
                handleDelete={handleDelete}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GiroTable;
