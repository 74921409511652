import { Badge, Button, Divider, Grid, IconButton, makeStyles, Menu, MenuItem, Theme, Tooltip, Typography } from '@material-ui/core';
import { Add, AddBox, Description, ExitToApp, Search, Today } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import DateRangeFilter from 'components/DateRangeFilter';
import { ACCOUNT_CATEGORY_BASE_URL, JOURNAL_TRANSACTIONS_BASE_URL, OPERATIONAL_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useDebounce from 'hooks/useDebounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import TransactionCreate from './components/OtherIncomeCreate';
import TransactionTable from './components/TransactionTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import { GREEN, WHITE } from 'constants/colors';
import CreateTransferInternal from './components/CreateTransferInternal';
import UpdateOtherIncome from './components/UpdateOtherIncome';
import OtherIncomeCreate from './components/OtherIncomeCreate';
import OperationalDelete from './components/TransactionDelete';
import TransactionDelete from './components/TransactionDelete';
import OtherExpensesCreate from './components/OtherExpensesCreate';
import SearchInput from 'components/SearchInput';
import useRole from 'hooks/useRole';
import { StandardConfirmationDialog } from 'components';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';
import TransactionalJurnalAuhtorization from './components/TransactionalJurnalAuhtorization';

export type TypeJournalTransactions = {
  isLoading: boolean;
  data: transactionJurnal[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '2em',
    marginTop: '2em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  buttonBack: {
    transform: `rotate(-180deg)`
  }
}));

const TransactionJurnalPage = () => {
  const classes = useStyles();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [journalTransactions, setJournalTransactions] = useState<TypeJournalTransactions>({
    isLoading: false,
    data: []
  });
  const [journalTransactionsId, setJournalTransactionsId] = useState<transactionJurnal | null>(null);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isCreateOtherExpenses, setIsCreateOrtherExpenses] = useState<boolean>(false);
  const [isUpdateOtherIncome, setIsUpdateOtherIncome] = useState<boolean>(false);
  const [isCreateTransferInternal, setIsCreateTransferInternal] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const { unsetCurrentUser, currentUser } = useContext(CurrentUserContext);
  const [authorizaion, setAuthorizaion] = useState<boolean>(false);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });
  const handleOpenCreate = () => {
    setJournalTransactionsId(null);
    setAnchorEl(null);
    setIsCreate(true);
  };
  const handleOpenCreateTransferInternal = () => {
    setJournalTransactionsId(null);
    setIsCreateTransferInternal(true);
    setAnchorEl(null);
    setJournalTransactions({
      isLoading: false,
      data: []
    });
  };

  const handleOpenCreateOtherExpenses = () => {
    setJournalTransactionsId(null);
    setAnchorEl(null);
    setIsCreateOrtherExpenses(true);
  };
  const handleCloseCreate = () => {
    setSubAccountCategories([]);
    setIsCreate(false);
  };

  const handleCloseCreateTransferInternal = useCallback(() => {
    setIsCreateTransferInternal(false);
    setSubAccountCategories([]);
  }, []);
  const handleCloseCreateOtherExpenses = useCallback(() => {
    setIsCreateOrtherExpenses(false);
    setSubAccountCategories([]);
  }, []);
  const handleCloseUpdate = useCallback(() => setIsUpdate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [type, setType] = useState<string>('');
  const [tab, setTab] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');
  const [transaksi, setTransaksi] = useState<string>('');
  const [akun, setAkun] = useState<string>('');
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [totalAuthorization, setTotalAuthorization] = useState<number>(0);
  const [isShown, setIsShown] = useState<boolean>(false);
  const [subAccountCategories, setSubAccountCategories] = useState<AccountCategoryModel[]>([]);
  const handleCloseUpdateOtherIncome = useCallback(() => {
    setIsUpdateOtherIncome(false);
    setSubAccountCategories([]);
  }, []);
  const [financialAccounts, setFinancialAccounts] = useState<AccountCategoryModel[]>([]);
  const [openCarousel, setOpenCarousel] = useState<{ isOpen: boolean; id: number }>({
    isOpen: false,
    id: 0
  });
  const [isAction, setIsAction] = useState<{
    isOpen: boolean;
    isLoading: boolean;
    id: number;
    action: 'delete' | 'update' | 'detail' | 'auhtorization';
    type: string;
  }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete',
    type: ''
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotif = async () => {
    try {
      const { data } = await axios.get(`${JOURNAL_TRANSACTIONS_BASE_URL}/autorization`);

      if (data.data > 0) {
        setIsShown(true);
      }

      setTotalAuthorization(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const fetchAccountData = useCallback(
    async endpoint => {
      try {
        const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/${endpoint}`, {
          cancelToken
        });
        setSubAccountCategories(data.data);
      } catch (error) {
        console.error(`Error fetching data for endpoint: ${endpoint}`, error);
      }
    },
    [cancelToken, ACCOUNT_CATEGORY_BASE_URL]
  );

  const getDataAccount = () => fetchAccountData('cost');
  const getAccountPassiva = () => fetchAccountData('passiva');
  const getAccountTax = () => fetchAccountData('tax');
  const getAccountLoan = () => fetchAccountData('loan');
  const getAccountCapitalInvestment = () => fetchAccountData('capital-investment');
  const getAccountOtherIncome = () => fetchAccountData('other-income');
  const getTaxExpenditure = () => fetchAccountData('tax-expenditure');
  const getAssetPurchase = () => fetchAccountData('asset-purchase');
  const getInstallmentPayments = () => fetchAccountData('installment-payments');
  const getEmployeeReceivables = () => fetchAccountData('employee-receivables');
  const getDividendDistribution = () => fetchAccountData('dividend-distribution');
  const getSalesDeposit = () => fetchAccountData('sales-deposit');
  const getPurchaseDeposit = () => fetchAccountData('purchase-deposit');

  const getFinancialAccounts = useCallback(async () => {
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/financial`, { cancelToken });
      setFinancialAccounts(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [cancelToken, ACCOUNT_CATEGORY_BASE_URL]);

  const fetchData = useCallback(async () => {
    setJournalTransactions(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (authorizaion) {
        params.append('auhtorization', 'true');
      }
      if (queryString) {
        params.append('keyword', queryString);
      }
      if (transaksi) {
        params.append('transaksi', transaksi);
      }
      if (akun) {
        params.append('akun', akun);
      }

      params.append('page', currentPage.toString());

      if (!authorizaion) {
        params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
        params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      }
      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }
      return params;
    };

    try {
      const { data } = await axios.get(`${JOURNAL_TRANSACTIONS_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setJournalTransactions(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage, authorizaion]);

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' | 'auhtorization' = 'delete', type: string) => {
    setIsAction(prev => ({ ...prev, isOpen, id, action }));

    if (action === 'update') {
      setJournalTransactionsId(null);
      setIsCreateTransferInternal(false);
      setIsCreate(false);
      handleOnUpdate(id);
      setType(type);

      if (type === 'Pemindahan Internal') {
        getDataAccount();
        setIsCreateTransferInternal(true);
      } else if (type === 'Pendapatan Bunga') {
        setIsUpdateOtherIncome(true);
        getAccountTax();
      } else if (type === 'Penjualan Activa') {
        setIsUpdateOtherIncome(true);
        getAccountPassiva();
      } else if (type === 'Pinjaman') {
        setIsUpdateOtherIncome(true);
        getAccountLoan();
      } else if (type === 'Penanaman Modal') {
        setIsUpdateOtherIncome(true);
        getAccountCapitalInvestment();
      } else if (type === 'Penerimaan Lain') {
        getAccountOtherIncome();
        setIsUpdateOtherIncome(true);
      } else if (type === 'Pajak') {
        getTaxExpenditure();
        setIsUpdateOtherIncome(true);
      } else if (type === 'Pembelian Activa') {
        getAssetPurchase();
        setIsUpdateOtherIncome(true);
      } else if (type === 'Pembayaran Cicilan') {
        getInstallmentPayments();
        setIsUpdateOtherIncome(true);
      } else if (type === 'Pembagian Dividen') {
        getDividendDistribution();
        setIsUpdateOtherIncome(true);
      } else if (type === 'Piutang Karyawan') {
        getEmployeeReceivables();
        setIsUpdateOtherIncome(true);
      } else if (type === 'Panjar Penjualan') {
        getSalesDeposit();
        setIsUpdateOtherIncome(true);
      } else if (type === 'Panjar Pembelian') {
        getPurchaseDeposit();
        setIsUpdateOtherIncome(true);
      } else {
        setIsUpdateOtherIncome(true);
      }
    } else if (action === 'detail') {
      setIsDetail(true);
      handleOnUpdate(id);
    }
  };

  const handleOnUpdate = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.get(`${JOURNAL_TRANSACTIONS_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
      setJournalTransactionsId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    getNotif();
    fetchData();
    getDataAccount();
    getFinancialAccounts();
  }, [fetchData, queryString]);

  const reFetchData = () => {
    setIsCreate(false);
    setIsUpdate(false);
    fetchData();
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleDelete = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.delete(`${JOURNAL_TRANSACTIONS_BASE_URL}/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
      handleSnackBar(true, 'success', 'Transaksi jurnal umum berhasil dihapus.');
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenAuthorization = () => {
    setAuthorizaion(true);
  };

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setQueryString('');
    setCurrentPage(1);
    setStartDate(format(new Date(startDate), 'yyyy-MM-dd'));
    setEndDate(format(new Date(endDate), 'yyyy-MM-dd'));
  };

  const handleCloseAuthorization = () => {
    setAuthorizaion(false);
  };

  useEffect(() => {
    getNotif();
    fetchData();
  }, [authorizaion]);

  const auhtorization = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(`${JOURNAL_TRANSACTIONS_BASE_URL}/autorization/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
      handleSnackBar(true, 'success', 'Autorisasi berhasil.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Autorisasi gagal.');
    }
  };

  const reject = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    console.log('tes');
    try {
      const { data } = await axios.get(`${JOURNAL_TRANSACTIONS_BASE_URL}/reject/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
      //handleSnackBar(true, 'success', 'Autorisasi berhasil.');
    } catch (error) {
      console.log(error);
      //handleSnackBar(true, 'error', 'Autorisasi gagal.');
    }
  };

  return (
    <Grid>
      <Grid container direction='row' spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container direction='row' justify='space-between'>
            <Grid item xl={7} lg={7} md={12} sm={12} xs={12} container alignItems='center'>
              <Tooltip title='Memuat Ulang'>
                <Button size='small' onClick={onRefresh} color='inherit' className={classes.refresh}>
                  <RefreshIcon fontSize='small' />
                </Button>
              </Tooltip>
              <Typography style={{ fontSize: '1rem', fontWeight: 400, marginLeft: '15px' }}>
                {`Menampilkan ${meta.total || 0} Transaksi (${meta.from || 0} - ${meta.to || 0} dari ${meta.total || 0})`}

                <Typography color='primary'>
                  Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                </Typography>
              </Typography>
            </Grid>

            <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
              <Grid item>
                <Tooltip title='Calendar filter' placement='top'>
                  <IconButton color='primary' aria-label='filter date' component='span' onClick={handleCalendarFilterClick}>
                    <Today />
                  </IconButton>
                </Tooltip>
                <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
                  Tambah Transaksi
                </Button>
                <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={handleOpenCreateTransferInternal}>Pemindahan Internal</MenuItem>
                  <MenuItem onClick={handleOpenCreate}>Buat Pemasukan Lain</MenuItem>
                  <MenuItem onClick={handleOpenCreateOtherExpenses}>Buat Pengeluaran Lain</MenuItem>
                </Menu>
                {isSuperAdmin && !authorizaion && (
                  <Badge
                    color='error'
                    badgeContent={totalAuthorization}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <Description
                      fontSize='large'
                      color='primary'
                      style={{
                        marginLeft: 12,
                        cursor: 'pointer',
                        pointerEvents: 'auto'
                      }}
                      //className={totalAuthorization > 0 ? classes.animationNotif : ''}
                      onClick={() => handleOpenAuthorization()}
                      onMouseEnter={() => setIsShown(true)}
                      onMouseLeave={() => setIsShown(false)}
                    />
                  </Badge>
                )}
                {isSuperAdmin && authorizaion && (
                  <IconButton color='primary' aria-label='back' component='span' className={classes.buttonBack}>
                    <ExitToApp fontSize='large' onClick={handleCloseAuthorization} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={12}>
          <TransactionTable
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setTransaksi={setTransaksi}
            setAkun={setAkun}
            akun={akun}
            transaksi={transaksi}
            journalTransactions={journalTransactions}
            handleAction={handleAction}
          />
        </Grid>
        {journalTransactions.data.length > 0 && (
          <Grid xs={12} container>
            <Pagination
              count={meta.last_page}
              onChange={(event, page) => setCurrentPage(page)}
              page={meta.current_page}
              boundaryCount={2}
              variant='outlined'
              shape='rounded'
            />
          </Grid>
        )}
        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => setOpenCalendarFilter(false)}
        />
      </Grid>

      <OtherIncomeCreate
        financialAccounts={financialAccounts}
        open={isCreate}
        handleCloseCreate={handleCloseCreate}
        reFetchData={reFetchData}
        handleSnackBar={handleSnackBar}
      />
      <OtherExpensesCreate
        financialAccounts={financialAccounts}
        open={isCreateOtherExpenses}
        handleCloseCreate={handleCloseCreateOtherExpenses}
        reFetchData={reFetchData}
        handleSnackBar={handleSnackBar}
      />

      <UpdateOtherIncome
        financialAccounts={financialAccounts}
        journalTransactionsId={journalTransactionsId}
        subAccountCategories={subAccountCategories}
        type={type}
        open={isUpdateOtherIncome}
        handleCloseUpdateOtherIncome={handleCloseUpdateOtherIncome}
        reFetchData={reFetchData}
        handleSnackBar={handleSnackBar}
      />

      <CreateTransferInternal
        financialAccounts={financialAccounts}
        subAccountCategories={subAccountCategories}
        open={isCreateTransferInternal}
        journalTransactionsId={journalTransactionsId}
        handleCloseCreate={handleCloseCreateTransferInternal}
        reFetchData={reFetchData}
        handleSnackBar={handleSnackBar}
      />

      <TransactionDelete
        open={isAction.isOpen && isAction.action.includes('delete')}
        handleClose={() => handleAction(false, 0, 'delete', '')}
        handleSubmit={handleDelete}
        isLoading={isAction.isLoading}
      />

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
        message={message}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        onConfirm={handleCloseSnackbar}
        noCancelButton={true}
      />

      <TransactionalJurnalAuhtorization
        open={isAction.isOpen && isAction.action.includes('auhtorization')}
        handleClose={() => handleAction(false, 0, 'auhtorization', '')}
        handleSubmit={auhtorization}
        handleReject={reject}
        isLoading={isAction.isLoading}
      />
    </Grid>
  );
};

export default TransactionJurnalPage;
