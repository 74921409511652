import {
  Badge,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';

import { ExitToApp, Description } from '@material-ui/icons';

import { Add, Search, Today } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, StandardConfirmationDialog, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import FlexBox from 'components/FlexBox';
import { ACCOUNT_CATEGORY_BASE_URL, OPERATIONAL_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useDebounce from 'hooks/useDebounce';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { dummyMetaData, dummyProductImage } from 'utils/dummy';
import OperationalCarousel from './components/OperationalCarousel';
import OperasionalCreate from './components/OperationalCreate';
import OperationalDelete from './components/OperationalDelete';
import OperationalDetail from './components/OperationalDetail';
import OperationalTable from './components/OperationalTable';
import OperationalUpdate from './components/OperationalUpdate';
import SearchInput from 'components/SearchInput';
import RefreshIcon from '@material-ui/icons/Refresh';
import { GREEN, WHITE } from 'constants/colors';
import useRole from 'hooks/useRole';
import useConfirmationPin from 'hooks/useConfirmationPin';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';
import OperationalAutorization from './components/OperationalAutorization';

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: Operasional[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  containerNotification: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center'
  },
  animationNotif: {
    animation: `$buttonNotifAnimation 2s infinite`
  },
  '@keyframes buttonNotifAnimation': {
    '0%': {
      transform: 'rotate(10deg)'
    },

    '10%': {
      transform: 'rotate(-10deg)'
    },
    '15%': {
      transform: 'rotate(10deg)'
    },
    '20%': {
      transform: 'rotate(-10deg)'
    },
    '25%': {
      transform: 'rotate(10deg)'
    },
    '30%': {
      transform: 'rotate(0deg)'
    }
  },
  buttonBack: {
    transform: `rotate(-180deg)`
  }
}));

const OperationalPage = () => {
  const classes = useStyles();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [operasionalResourceId, setOperasionalResourceId] = useState<Operasional | null>(null);
  const { unsetCurrentUser, currentUser } = useContext(CurrentUserContext);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const handleOpenCreate = () => setIsCreate(true);
  const handleCloseCreate = useCallback(() => setIsCreate(false), []);
  const handleCloseUpdate = useCallback(() => setIsUpdate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [subAccountCategories, setSubAccountCategories] = useState<AccountCategoryModel[]>([]);
  const [financialAccounts, setFinancialAccounts] = useState<AccountCategoryModel[]>([]);
  const [openCarousel, setOpenCarousel] = useState<{ isOpen: boolean; id: number }>({
    isOpen: false,
    id: 0
  });
  const [isShown, setIsShown] = useState<boolean>(false);
  const [isAction, setIsAction] = useState<{
    isOpen: boolean;
    isLoading: boolean;
    id: number;
    action: 'delete' | 'update' | 'detail' | 'auhtorization';
  }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete'
  });
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [totalAuthorization, setTotalAuthorization] = useState<number>(0);
  const [authorizaion, setAuthorizaion] = useState<boolean>(false);

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const handleOpenCarousel = (isOpen: boolean = true, id: number) => setOpenCarousel(prev => ({ ...prev, isOpen, id }));

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });
  const getDataAccount = useCallback(async () => {
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/cost`, { cancelToken });
      setSubAccountCategories(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [cancelToken, ACCOUNT_CATEGORY_BASE_URL]);

  const getFinancialAccounts = useCallback(async () => {
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/financial`, { cancelToken });
      setFinancialAccounts(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [cancelToken, ACCOUNT_CATEGORY_BASE_URL]);

  const fetchData = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }
      if (authorizaion) {
        params.append('auhtorization', 'true');
      }

      params.append('page', currentPage.toString());
      if (!authorizaion) {
        params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
        params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      }
      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }
      return params;
    };

    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setOperasionalResource(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage, authorizaion]);

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'auhtorization' | 'detail' = 'delete') => {
    setIsAction(prev => ({ ...prev, isOpen, id, action }));
    if (action === 'update') {
      setIsUpdate(true);
      handleOnUpdate(id);
    } else if (action === 'detail') {
      setIsDetail(true);
      handleOnUpdate(id);
    }
  };

  const handleOnUpdate = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
      setOperasionalResourceId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  useEffect(() => {
    fetchData();
    getNotif();
    getDataAccount();
    getFinancialAccounts();
  }, [fetchData, queryString, startDate, endDate]);

  const handleSearch = useCallback(
    (searchQuery: string) => {
      performActionAndRevertPage(setQueryString, searchQuery);
    },
    [performActionAndRevertPage]
  );

  const debouncedSearchTerm = useDebounce(query, 500);

  const reFetchData = () => {
    setIsCreate(false);
    setIsUpdate(false);
    fetchData();
    getNotif();
  };

  const auhtorization = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}/autorization/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
      handleSnackBar(true, 'success', 'Autorisasi berhasil.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Autorisasi gagal.');
    }
  };
  const reject = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      console.log('tes');
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}/reject/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.delete(`${OPERATIONAL_BASE_URL}/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
      handleSnackBar(true, 'success', 'Biaya berhasil dihapus.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Biaya gagal dihapus.');
    }
  };

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setQueryString('');
    setCurrentPage(1);
    setStartDate(format(new Date(startDate), 'yyyy-MM-dd'));
    setEndDate(format(new Date(endDate), 'yyyy-MM-dd'));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseAuthorization = () => {
    setAuthorizaion(false);
  };

  const handleOpenAuthorization = () => {
    setAuthorizaion(true);
  };

  const getNotif = async () => {
    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}/autorization`);

      if (data.data > 0) {
        setIsShown(true);
      }

      setTotalAuthorization(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    getNotif();
    fetchData();
  }, [authorizaion]);

  return (
    <Page title='Biaya'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Biaya
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container direction='row' justify='space-between'>
              <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
                <Grid item>
                  <FormControl fullWidth variant='outlined' hiddenLabel size='small'>
                    <SearchInput
                      withBorder
                      withTransition={false}
                      width={150}
                      placeHolder='Cari...'
                      iconColor='#989898'
                      tableSearchValue={query}
                      setTableSearchValue={setQuery}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xl={5} lg={5} md={12} sm={12} xs={12} container alignItems='center' justify='flex-end' style={{ columnGap: '1em' }}>
                <Grid item>
                  <Tooltip title='Calendar filter' placement='top'>
                    <IconButton color='primary' aria-label='filter date' component='span' onClick={handleCalendarFilterClick}>
                      <Today />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Tambah data' placement='top'>
                    <Button onClick={handleOpenCreate} size='small' startIcon={<Add fontSize='small' />}>
                      Tambah Biaya
                    </Button>
                  </Tooltip>
                  {isSuperAdmin && !authorizaion && (
                    <Badge
                      color='error'
                      badgeContent={totalAuthorization}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <Description
                        fontSize='large'
                        color='primary'
                        style={{
                          marginLeft: 12,
                          cursor: 'pointer',
                          pointerEvents: 'auto'
                        }}
                        onClick={() => handleOpenAuthorization()}
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                      />
                    </Badge>
                  )}
                  {isSuperAdmin && authorizaion && (
                    <IconButton color='primary' aria-label='back' component='span' className={classes.buttonBack}>
                      <ExitToApp fontSize='large' onClick={handleCloseAuthorization} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} container className={classes.container}>
            <Grid container direction='row' spacing={2}>
              {' '}
              <Grid item xl={6} lg={1} md={1} sm={6} xs={6}>
                <Tooltip title='Memuat Ulang'>
                  <Button onClick={onRefresh} color='inherit' className={classes.refresh}>
                    <RefreshIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid container alignItems='center' item xl={12} lg={8} md={12} sm={12} xs={12}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='h6'>
                    {`Menampilkan ${meta.total || 0} Biaya   (${meta.from || 0} - ${meta.to || 0} dari ${meta.total || 0})`}
                  </Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography color='primary'>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12}>
              <OperationalTable operasionalResource={operasionalResource} handleAction={handleAction} handleOpenCarousel={handleOpenCarousel} />
            </Grid>
            {operasionalResource.data.length > 0 && (
              <Grid xs={12} container>
                <Pagination
                  count={meta.last_page}
                  onChange={(event, page) => setCurrentPage(page)}
                  page={meta.current_page}
                  boundaryCount={2}
                  variant='outlined'
                  shape='rounded'
                />
              </Grid>
            )}
            <DateRangeFilter
              openCalendarFilter={openCalendarFilter}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleClose={() => setOpenCalendarFilter(false)}
            />
          </Grid>

          <OperasionalCreate
            financialAccounts={financialAccounts}
            subAccountCategories={subAccountCategories}
            open={isCreate}
            handleCloseCreate={handleCloseCreate}
            reFetchData={reFetchData}
            handleSnackBar={handleSnackBar}
          />
          <OperationalUpdate
            financialAccounts={financialAccounts}
            subAccountCategories={subAccountCategories}
            operasionalResourceId={operasionalResourceId}
            open={isUpdate}
            handleCloseUpdate={handleCloseUpdate}
            reFetchData={reFetchData}
            handleSnackBar={handleSnackBar}
          />
          <OperationalDelete
            open={isAction.isOpen && isAction.action.includes('delete')}
            handleClose={() => handleAction(false, 0, 'delete')}
            handleSubmit={handleDelete}
            isLoading={isAction.isLoading}
          />

          <OperationalAutorization
            open={isAction.isOpen && isAction.action.includes('auhtorization')}
            handleClose={() => handleAction(false, 0, 'auhtorization')}
            handleSubmit={auhtorization}
            handleReject={reject}
            isLoading={isAction.isLoading}
          />
          <OperationalDetail open={isDetail} handleAction={handleAction} operasionalResourceId={operasionalResourceId} />
          <OperationalCarousel
            open={openCarousel.isOpen}
            handleClose={() => setOpenCarousel(prev => ({ ...prev, isOpen: false }))}
            images={
              operasionalResource.data.filter(operasionalResource => operasionalResource.id === openCarousel.id).length > 0
                ? operasionalResource.data.filter(operasionalResource => operasionalResource.id === openCarousel.id)[0].image
                : [dummyProductImage]
            }
          />
          <StandardConfirmationDialog
            variant={snackbarVariant}
            titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
            message={message}
            open={openSnackbar}
            handleClose={handleCloseSnackbar}
            onConfirm={handleCloseSnackbar}
            noCancelButton={true}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default OperationalPage;
