import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Create, Delete, DeleteOutline, EditOutlined, Visibility, AttachFile, VisibilityOutlined, DescriptionOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';

import { BLACK, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React from 'react';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  cellStart: {
    width: '3%'
  },
  cellMiddle: {
    width: '14%'
  },
  cellMiddleDesc: {
    width: '15%'
  },
  cellEnd: {
    width: '15%'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  },
  gap: {
    marginBottom: theme.spacing(2)
  }
}));
interface Props {
  journalTransactions: transactionJurnal;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' | 'auhtorization', type: string) => void;
}
const TransactionBody = ({ journalTransactions, handleAction }: Props) => {
  const classes = useStyles();
  return (
    <>
      <TableRowCustom key={journalTransactions.id}>
        <TableCellStart className={clsx(classes.cellMiddle)}>{journalTransactions.jurnalNumber}</TableCellStart>
        <TableCellMiddle className={clsx(classes.cellMiddle)}>{journalTransactions.type}</TableCellMiddle>

        <TableCellMiddle className={clsx(classes.cellMiddle)}>
          {journalTransactions.debit > 0 && journalTransactions.type !== 'Pemindahan Internal' ? (
            <>
              <div className={classes.gap}>{journalTransactions.financialAccountName}</div>
              <div className={classes.gap}>{journalTransactions.accountCategoriesName}</div>
            </>
          ) : (
            <>
              <div className={classes.gap}>{journalTransactions.accountCategoriesName}</div>
              <div className={classes.gap}>{journalTransactions.financialAccountName}</div>
            </>
          )}
        </TableCellMiddle>
        <TableCellMiddle className={clsx(classes.cellMiddle)} align='center'>
          {journalTransactions.debit > 0 ? (
            <>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.debit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.credit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
            </>
          ) : (
            <>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.credit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.debit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
            </>
          )}
        </TableCellMiddle>

        <TableCellMiddle className={clsx(classes.cellMiddle)} align='center'>
          {journalTransactions.credit > 0 ? (
            <>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.debit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.credit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
            </>
          ) : (
            <>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.credit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
              <div className={classes.gap}>
                <NumberFormat value={journalTransactions.debit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
              </div>
            </>
          )}
        </TableCellMiddle>

        <TableCellMiddle className={classes.cellMiddle} align='center'>
          {format(new Date(journalTransactions.date), 'dd MMM yyyy')}
        </TableCellMiddle>
        <TableCellMiddle className={classes.cellMiddle} align='center'>
          {format(new Date(journalTransactions.createdAt), 'dd MMM yyyy')}
        </TableCellMiddle>
        <TableCellEnd className={classes.cellEnd} align='right'>
          <>
            {journalTransactions.autorisasi ? (
              <Tooltip title='Autorisasi'>
                <IconButton size='small' onClick={() => handleAction(true, journalTransactions.id, 'auhtorization', journalTransactions.type)}>
                  <DescriptionOutlined fontSize='small' />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title='Perbaharui Biaya'>
              <IconButton size='small' onClick={() => handleAction(true, journalTransactions.id, 'update', journalTransactions.type)}>
                <EditOutlined fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Hapus Biaya'>
              <IconButton size='small' onClick={() => handleAction(true, journalTransactions.id, 'delete', journalTransactions.type)}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        </TableCellEnd>
      </TableRowCustom>
    </>
  );
};

export default TransactionBody;
