import { CashBalance } from 'typings/CashBalanceModel';

export const dummyProduct: ProductModel = {
  id: 0,
  productName: '',
  productCode: '',
  sellingPrice: 0,
  purchasePrice: 0,
  typeUnit: '',
  totalStock: 0,
  description: '',
  isReminder: false,
  minimumStock: 0,
  hardSelling: false,
  populerItem: false,
  isActive: false,
  warehouse: ''
};
export const dummyOperasional: Operasional = {
  id: 0,
  name: '',
  financialAccount: 0,
  accountCategoriesName: '',
  costNumber: '',
  financialAccountName: '',
  accountCategories: 0,
  date: new Date().toISOString().split('T')[0],
  vendor: '',
  autorisasi: false,
  amount: 0,
  image: [],
  description: '',
  createdAt: ''
};

export const dummycostBalance: CashBalance = {
  id: 0,
  date: new Date().toISOString().split('T')[0],
  total: 0,
  financialAccountName: '',
  financialAccount: 0,
  createdAt: ''
};

export const dummyTransactionJurnal: transactionJurnal = {
  id: 0,
  name: '',
  date: new Date().toISOString().split('T')[0],
  credit: 0,
  debit: 0,
  type: '',
  jurnalNumber: '',
  financialAccount: -1,
  financialAccountName: '',
  financialAccountBefore: 0,
  accountCategoriesId: -1,
  partnerId: 0,
  accountCategoriesName: '',
  vendor: '',
  description: '',
  createdAt: '',
  autorisasi: false
};

export const dummyDelivery = {
  id: 0,
  deliveryNumber: '',
  createdAt: '',
  partnerName: '',
  InvoiceId: [
    {
      InvoiceId: 0,
      invoiceNumber: ''
    }
  ],
  requestPrint: false,
  canPrint: false,
  zoneName: '',
  totalKoli: 0,
  totalInvoice: 0,
  salesName: '',
  printCount: 0
};
export const dummyMetaData = {
  current_page: 1,
  from: 0,
  last_page: 0,
  path: 'http://localhost:8000/',
  per_page: 24,
  to: 0,
  total: 0
};

export const dummyCollyFinal: typeCollyFinal = {
  SalesOrderId: 0,
  WareHouseId: 0,
  name: 'string',
  packName: 'string;',
  packCode: 'string;',
  items: [
    {
      id: 0,
      SalesOrderItemId: 0,

      typeUnit: '',
      totalItem: 0,
      totalItemBefore: 0
    }
  ]
};

export const dummyCollyFinalUpdate: typeCollyModelUpdate = {
  id: 0,
  SalesOrderId: 0,
  name: 'string',
  packName: 'string',
  packCode: 'string',
  BagItem: [
    {
      id: 0,
      SalesOrderItemId: 0,
      productName: 'string;',
      totalItem: 0,
      productImage: '',
      remark: '',
      totalItemBefore: 0
    }
  ],
  totalItem: 0
};

export const dummyCategory: CategoryModel = {
  id: 0,
  name: '',
  code: '',
  description: ''
};

export const dummyAppLog: AppLogsModel = {
  id: 0,
  initial: '',
  description: '-',
  createdAt: '',
  time: '',
  oldValue: '-',
  newValue: '-'
};

export const dummyProductImage: ProductImageModel = {
  id: 0,
  path: '',
  url: ''
};

export const dummySalesOrder: SalesOrderModel = {
  id: 0,
  orderNumber: '',
  PartnerId: 0,
  SalesId: 0,
  dueDate: new Date(),
  orderDate: new Date(),
  cityAddress: 'OUT',
  totalItem: 0,
  totalPrice: 0,
  totalDiscount: 0,
  totalSisa: 0,
  statusOrder: 'PENDING',
  terms: '',
  notes: ''
};

export const dummyInvoice: InvoiceModel = {
  id: 0,
  invoiceNumber: '',
  PartnerId: 0,
  SalesId: 0,
  dueDate: new Date(),
  orderDate: new Date(),
  totalItem: 0,
  totalPrice: 0,
  totalDiscount: 0,
  terms: '',
  notes: '',
  totalPay: 0,
  totalPiece: 0,
  statusPayment: 'UNPAID',
  rounding: 0
};

export const dummyInvoiceReturnBill: ReturnBill = {
  id: 0,
  invoiceNumber: '',
  PartnerId: 0,
  totalItem: 0,
  totalPrice: 0,
  totalPay: 0,
  zoneName: '',
  statusPayment: 'UNPAID',
  createdAt: '',
  partnerName: ''
};

export const dummyPurchaseOrder: PurchaseOrderModel = {
  id: 0,
  orderNumber: '',
  PartnerId: 0,
  WareHouseId: null,
  PurchaseInvoiceId: null,
  orderDate: new Date(),
  travelDate: new Date(),
  logisticPrice: 0,
  totalItem: 0,
  statusOrder: 'PENDING',
  notes: ''
};

export const dummyPurchaseItem: PurchaseOrderItemModel = {
  id: 0,
  PurchaseOrderId: 0,
  ProductId: 0,
  typeUnit: '',
  totalItem: 0
};

export const dummyUser: UserDetailsModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  contactNumber: '',
  name: '',
  role: '',
  roleId: 0,
  type: 'SALES'
};

export const dummyCurrentUser: CurrentUser = {
  id: 0,
  displayName: 'A',
  email: '',
  firstName: '',
  lastName: '',
  WareHouseId: [],
  contactNumber: '',
  permission: [],
  WareHouseName: [],
  type: 'SALES',
  isNew: false
};

export const dummyPartner: PartnerModel = {
  id: 0,
  partnerId: '',
  name: '',
  address: '',
  areaCode: '',
  businessEntity: '',
  cellPhoneNumber: '',
  email: '',
  notes: '',
  phoneNumber: '',
  postalCode: 0,
  regionName: '',
  virtualAccount: '',
  website: '',
  partnerType: 'CUSTOMER',
  dueDate: 0
};

export const dummyZone: ZoneModel = {
  id: 0,
  name: '',
  description: ''
};

export const dummyItem: SalesOrderItemModel = {
  id: 0,
  SalesOrderId: 0,
  ProductId: 0,
  typeUnit: '',
  totalItem: 0,
  totalInBag: 0,
  price: 0,
  subTotalPrice: 0,
  totalPrice: 0,
  discount: 0,
  status: 'PENDING'
};

export const dummyItemInvoice: InvoiceItemModel = {
  id: 0,
  InvoiceId: 0,
  ProductId: 0,
  typeUnit: '',
  totalItem: 0,
  price: 0,
  subTotalPrice: 0,
  totalPrice: 0,
  discount: 0
};

export const dummyCompany: CompanyModel = {
  id: 0,
  name: '',
  address: '',
  areaCode: '',
  npwpNumber: '',
  cellPhoneNumber: '',
  email: '',
  contactPerson: '',
  phoneNumber: '',
  postalCode: 0,
  website: '',
  image: ''
};
export const dummyProductSales: ProductSalesModel = {
  id: 0,
  outDate: new Date(),
  salesNumber: '',
  totalPrice: 0,
  description: ''
};
export const dummyWareHouse: WareHouseModel = {
  id: 0,
  name: '',
  address: '',
  description: ''
};

export const dummyStock: StockModel = {
  id: 0,
  enterDate: new Date(),
  purchasesNumber: '',
  totalPrice: 0,
  totalItem: 0,
  description: '',
  PartnerId: 0
};

export const dummyRegion: RegionModel = {
  code: '',
  name: ''
};

export const dummyProductItem: ProductItemModel = {
  id: 0,
  ProductId: 0,
  minimumItem: 0,
  promoPrice: 0,
  bonusItem: 0
};

export const dummyProductPrice: ProductPriceModel = {
  id: 0,
  price: 0,
  ProductId: 0,
  ZoneId: 0
};

export const dummyStockItem = {
  id: 0,
  typeUnit: '',
  purchasePrice: 0,
  totalPrice: 0,
  totalItem: 0,
  logisticPrice: 0,
  unitNetPrice: 0,
  ProductId: 0
};

export const dummyPartnerMinim = {
  id: 0,
  name: ''
};

export const dummyInvoicePayment: InvoicePaymentModel = {
  id: 0,
  paymentNumber: '',
  InvoiceId: 0,
  ReturnId: null,
  PaymentId: null,
  useReturn: false,
  InvoicePiece: [],
  payDate: new Date(),
  totalPay: 0,
  paymentMethod: null,
  note: '',
  BankId: null,
  SalesId: null,
  giroBank: '',
  InvoicePaymentMultiple: [],
  giroDueDate: new Date(),
  giroDate: new Date(),
  giroAmount: '',
  giroNumber: '',
  otherPiece: 0,
  autorisasi: false
};
export const dummyPurchaseInvoicePayment: PurchaseInvoicePaymentModel = {
  id: 0,
  paymentNumber: '',
  PurchaseInvoiceId: 0,
  ReturnId: null,
  PaymentId: null,
  useReturn: false,
  InvoicePiece: [],
  payDate: new Date(),
  totalPay: 0,
  paymentMethod: null,
  note: '',
  BankId: null,
  SalesId: null,
  giroBank: '',
  PurchaseInvoicePaymentMultiple: [],
  giroDueDate: new Date(),
  giroDate: new Date(),
  giroAmount: '',
  giroNumber: '',
  otherPiece: 0,
  autorisasi: false
};

export const dummyCommissiom: CommissionModel = {
  id: 0,
  month: '',
  monthNumber: 0,
  year: 0,
  SalesId: 0,
  totalInvoice: 0,
  totalBill: 0,
  totalCommission: 0,
  statusCommission: ''
};

export const dummyPackage: ProductPackageModel = {
  id: 0,
  name: '',
  totalPrice: 0,
  description: ''
};

export const dummyStockOut: StockOutModel = {
  id: 0,
  outDate: new Date(),
  salesNumber: '',
  totalPrice: 0,
  totalItem: 0,
  description: '',
  PartnerId: 0
};

export const dummyAccess: AccessModel = {
  id: 0,
  accessId: '',
  level: '',
  access: ''
};

export const dummyPurchaseInvoice: PurchaseInvoiceModel = {
  id: 0,
  invoiceNumber: '',
  invoiceNumberSupplier: '',
  PartnerId: 0,
  dueDate: new Date(),
  orderDate: new Date(),
  totalItem: 0,
  isPrint: false,
  totalPrice: 0,
  totalPiece: 0,
  totalDiscount: 0,
  notes: '',
  totalPay: 0,
  statusPayment: 'UNPAID',
  logisticPrice: 0
};

export const dummyPurchaseInvoiceItem: PurchaseInvoiceItemModel = {
  id: 0,
  PurchaseInvoiceId: 0,
  ProductId: 0,
  typeUnit: '',
  totalItem: 0,
  price: 0,
  subTotalPrice: 0,
  totalPrice: 0,
  discount: 0,
  unitNetPrice: 0
};

export const dummyStokAllItem: StockAllItemModel = {
  id: 0,
  typeUnit: '-',
  productName: '-',
  productCode: '-',
  categoryName: '-',
  warehouseName: '-',
  totalStock: 0,
  totalOut: 0,
  supplier: [],
  holdItem: 0,
  netStock: 0,
  salesStock: 0,
  totalDamage: 0,
  totalDamageEdit: 0,
  totalReturn: 0
};

export const dummyInvoiceReturn: InvoiceReturnModel = {
  id: 0,
  returnDate: new Date(),
  returnNumber: '',
  SalesId: 0,
  totalItem: 0,
  totalPrice: 0,
  totalDiscount: 0,
  notes: '',
  typeReturn: 'CUSTOMER',
  status: null,
  totalCredit: 0
};

export const dummyCart: CartModel = {
  UserId: 0,
  ZoneId: 0
};

export const dummyCartList: CartListModel = {
  id: 0,
  SalesOrderId: 0,
  ProductId: 0,
  typeUnit: '',
  totalItem: 0,
  totalInBag: 0,
  price: 0,
  subTotalPrice: 0,
  totalPrice: 0,
  discount: 0,
  status: 'PENDING'
};

export const dummyInvoicePiece: InvoicePieceModel = {
  id: 0,
  price: 0,
  type: 'OTHER',
  note: '',
  InvoiceId: 0,
  InvoiceReturnId: 0,
  returnNumber: ''
};

export const dummyPurchaseInvoicePiece: PurchaseInvoicePieceModel = {
  id: 0,
  price: 0,
  type: 'OTHER',
  note: '',
  purchaseInvoiceId: 0
};

export const dummyBank: BankModel = {
  id: 0,
  bankName: '',
  bankNumber: '',
  bankAccount: '',
  bankCode: '',
  accountCode: 0
};

export const dummyStockCheck: StockCheckModel = {
  id: 0,
  checkDate: '',
  WareHouseId: 0
};

export const dummyStockCheckItem: StockCheckItemModel = {
  id: 0,
  ProductId: 0,
  StockCheckId: 0,
  typeUnit: '',
  firstStock: 0,
  currentItem: 0,
  adjustItem: 0,
  adjustPrice: 0,
  purchasePrice: 0,
  isAdjustment: false
};

export const dummyReturnItem: InvoiceReturnItemModel = {
  id: 0,
  InvoiceReturnId: 0,
  ProductId: 0,
  typeUnit: '',
  totalItem: 0,
  price: 0,
  subTotalPrice: 0,
  totalPrice: 0,
  discount: 0,
  totalDamage: 0,
  totalReturned: 0,
  totalMerge: 0
};

export const dummyMiniReturnItem: { id: number; totalDamage: number; totalMerge: number; totalReturned: number } = {
  id: 0,
  totalDamage: 0,
  totalMerge: 0,
  totalReturned: 0
};

export const dummyMutation: MutationModel = {
  id: 0,
  mutationNumber: '',
  mutationDate: new Date().toString(),
  status: '',
  totalItem: 0,
  totalQuantity: 0,
  DestinationId: 0,
  OriginId: 0,
  mutationBy: 0,
  acceptedBy: 0,
  notes: ''
};

export const dummyMutationItem: MutationItemModel = {
  id: 0,
  ProductId: 0,
  typeUnit: '',
  MutationId: 0,
  totalItem: 0
};
