import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

interface Props {
  giro: GiroModalModel;
  index: number;
  isLoading: boolean;
  isUpdate: boolean;
  totalPay: number;
  handleDelete: (giro: GiroModalModel) => void;
}

const BodyRow: FC<Props> = props => {
  const { giro, isLoading, isUpdate, totalPay, handleDelete } = props;
  const handleClickDelete = () => {
    handleDelete(giro);
  };
  return (
    <TableRowCustom>
      <TableCellStart align='center'>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : format(new Date(giro.giroDueDate), 'dd/MM/yyyy')}
      </TableCellStart>
      <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : giro.giroBank}</TableCellMiddle>
      <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : giro.giroNumber}</TableCellMiddle>

      <TableCellMiddle align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={giro.giroAmount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellEnd align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Button variant='contained' size='small' onClick={handleClickDelete} disabled={isUpdate}>
            Delete
          </Button>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
