import React from 'react';
import { TypeJournalTransactions } from 'pages/TransactionJurnalPage';
import {
  Grid,
  makeStyles,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components';
import { Skeleton } from '@material-ui/lab';
import TransactionBody from './TransactionBody';

interface Props {
  journalTransactions: TypeJournalTransactions;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' | 'auhtorization', type: string) => void;
  setTransaksi: React.Dispatch<React.SetStateAction<string>>;
  transaksi: string;
  setAkun: React.Dispatch<React.SetStateAction<string>>;
  akun: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTableSecond: {
    width: '5%'
  },
  container: {
    minHeight: '30vh',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)'
  },
  cellStartId: {
    width: '5%'
  },
  cellTable3: {
    width: '13%'
  }
}));
const TransactionTable = ({ setOrder, setOrderBy, setTransaksi, transaksi, setAkun, akun, journalTransactions, handleAction }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            headers={[
              { id: 'id', label: 'Id', align: 'center' },
              { id: 'transaksi', label: 'Transaksi', align: 'center' },
              { id: 'akun', label: 'Akun Keuangan', align: 'center' },
              { id: 'debit', label: 'Debit', align: 'center' },
              { id: 'credit', label: 'Credit', align: 'center' },
              { id: 'date', label: 'Tanggal', align: 'center' },
              { id: 'createdAt', label: 'Tanggal Dibuat', align: 'center' },
              { id: 'action', label: 'Aksi', align: 'right' }
            ]}
          />
        </TableHead>

        <TableBody>
          {journalTransactions.isLoading ? (
            <TableRow>
              <TableCell colSpan={8}>
                <Grid xs={12} container>
                  {[...new Array(5)].map((val, key) => (
                    <Grid xs={12}>
                      <Skeleton variant='text' animation='wave' height={60} key={key} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          ) : journalTransactions.data.length > 0 ? (
            journalTransactions.data.map((journalTransactions, key) => (
              <TransactionBody journalTransactions={journalTransactions} handleAction={handleAction} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align='center'>
                <Grid xs={12} className={classes.container} container alignItems='center' justify='center'>
                  <Typography variant='h6'>Data Tidak Tersedia.</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default TransactionTable;
