import React, { memo, useState } from 'react';
import { DialogContent, Dialog, Grid, Typography, IconButton, TextField, makeStyles, Button, CircularProgress, MenuItem } from '@material-ui/core';
import { OPERATIONAL_BASE_URL } from 'constants/url';
import { Close, CloudDownload } from '@material-ui/icons';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyOperasional } from 'utils/dummy';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  }
});
interface FinancialAccountsModel {
  name: string;
}
interface Props {
  financialAccounts: AccountCategoryModel[];
  subAccountCategories: AccountCategoryModel[];
  open: boolean;
  handleCloseCreate: () => void;
  reFetchData: () => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const OperasionalCreate = ({ handleSnackBar, financialAccounts, subAccountCategories, open, handleCloseCreate, reFetchData }: Props) => {
  const classes = useStyles();
  const [image, setImage] = useState<{ path: string }[]>([]);
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [operasional, setOperasional] = useState<Operasional>(dummyOperasional);
  // const [financialAccounts, setFinancialAccounts] = useState<FinancialAccountsModel[]>([{ name: 'Kas Kecil' }, { name: 'Bank' }]);

  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const disabled = operasional.name === '' || operasional.financialAccount === 0 || operasional.accountCategories === 0 || operasional.amount === 0;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setOperasional(prev => ({ ...prev, [id]: value }));
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = event.target.value;
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
      setOperasional(prev => ({
        ...prev,
        date: inputDate
      }));
    } else {
      console.error('Invalid date format:', inputDate);
    }
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    const dataFinal = {
      ...operasional,
      image
    };

    try {
      const { data } = await axios.post(OPERATIONAL_BASE_URL, dataFinal);

      if (image.length > 0) {
        let i = 0;
        data.data.image.map(async (value: any, index: number) => {
          if (image.filter(img => img.path === value.path).length) {
            // @ts-ignore
            const newImageKey = value.path;
            const fileExtension = newImageKey.split('.').pop();

            const myHeaders = new Headers();
            myHeaders.append('Content-Type', `image/${fileExtension}`);

            const config: RequestInit = {
              method: 'PUT',
              headers: myHeaders,
              body: imageBlob[i]
            };

            await fetch(value.url, config)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));

            i++;
          }
        });
      }

      reFetchData();
      reMounted();
      handleSnackBar(true, 'success', 'Biaya berhasil ditambahkan.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Biaya gagal ditambah.');
    }
  };

  const reMounted = () => {
    setOperasional(dummyOperasional);
    setIsSubmit(false);
    setImage([]);
    setImageBlob([]);
    setImageUrl([]);
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 5,
    onDrop: acceptedFiles => {
      setImage([]);
      setImageBlob([]);
      uploadImage(acceptedFiles);
    }
  });

  const uploadImage = (files: FileWithPath[]) => {
    files.map((value: any) => {
      setImageUrl([...imageUrl, URL.createObjectURL(value)]);
      setImage(prevState => [...prevState, { path: `${operasional.name}${new Date().getTime()}.${value.name.split('.').pop()}` }]);
      setImageBlob(prevState => [...prevState, value]);
    });
  };

  const handleCloseCreates = () => {
    reMounted();
    handleCloseCreate();
  };
  const handleDeleteImage = (index: number) => {
    setImageUrl(imageUrl.filter((val, key) => index !== key));
    setImage(image.filter((val, key) => index !== key));
    setImageBlob(imageBlob.filter((val, key) => index !== key));
  };

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>Tambah Biaya </Typography>
          <IconButton onClick={handleCloseCreates} size='small'>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row'>
          <Grid xs={12}>
            <TextField
              id='name'
              placeholder='cth. Biaya bensin, biaya parkir, biaya gaji karyawan'
              required
              fullWidth
              autoComplete='off'
              label='Nama Biaya'
              value={operasional.name}
              onChange={handleChange}
            />

            <TextField
              id='date'
              required
              fullWidth
              name='date'
              label='Tanggal'
              type='date'
              value={operasional.date || new Date().toISOString().split('T')[0]}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true
              }}
            />

            <Autocomplete
              id='accountCategories'
              options={subAccountCategories}
              getOptionLabel={option => `${option.number} - ${option.name}`}
              value={
                operasional.accountCategories !== undefined
                  ? subAccountCategories.find(item => item.id === operasional.accountCategories) || null
                  : null
              }
              onChange={(event, newValue) => {
                setOperasional(prev => ({
                  ...prev,
                  accountCategories: newValue ? newValue.id : 0
                }));
              }}
              renderInput={params => <TextField {...params} required label='Akun Beban' placeholder='Tidak ada akun beban yang dipilih' fullWidth />}
              getOptionDisabled={option => option.id === 0}
            />

            <TextField
              id='vendor'
              placeholder='cth. pertamina,  toko bangunan sinar baru'
              fullWidth
              autoComplete='off'
              label='Nama Vendor'
              value={operasional.vendor}
              onChange={handleChange}
            />
            <Autocomplete
              id='financialAccount'
              options={financialAccounts}
              getOptionLabel={option => `${option.number} - ${option.name}`}
              value={
                operasional.financialAccount !== undefined ? financialAccounts.find(item => item.id === operasional.financialAccount) || null : null
              }
              onChange={(event, newValue) => {
                setOperasional(prev => ({
                  ...prev,
                  financialAccount: newValue ? newValue.id : 0
                }));
              }}
              renderInput={params => (
                <TextField {...params} required placeholder='Tidak ada akun keuangan yang dipilih' label='Akun Keuangan' fullWidth />
              )}
              getOptionDisabled={option => option.id === 0}
            />
            <TextField
              id='amount'
              required
              fullWidth
              placeholder='Isi nominal transaksi'
              name='amount'
              autoComplete='off'
              label='Jumlah'
              value={operasional.amount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOperasional(prev => ({ ...prev, ['amount']: +event.target.value }))}
              InputProps={{
                inputComponent: NumberFormatMask as any
              }}
            />
            <TextField
              id='description'
              label='Keterangan'
              placeholder='cth. parkir di mall,  gaji karyawan 1 2 3'
              multiline
              rows={4}
              variant='outlined'
              fullWidth
              value={operasional.description}
              onChange={handleChange}
            />
            <Grid xs={12} container className={classes.containerImage}>
              <Grid xs={12} container direction='row' className={classes.uploadImage} alignItems='center'>
                <Grid xs={2}>
                  <CloudDownload color='primary' className={classes.iconLarge} />
                </Grid>
                <Grid xs={10} {...getRootProps()}>
                  <Typography variant='h6'>Unggah Gambar</Typography>
                  <input {...getInputProps()} />
                  <Typography color='textSecondary'>
                    Seret dan lepas gambar di sini, atau klik <u className={classes.browser}> di sini</u> untuk memilih file (maksimal 3 foto).
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} container className={classes.wrapperImage}>
                {imageUrl.map((image, index) => (
                  <Grid className={classes.containerBoxImage} key={index}>
                    <img className={classes.image} src={image} alt='images-product' />
                    <Grid container className={classes.closeImage} justify='center' alignItems='center' xs={2}>
                      <Close className={classes.closeIcon} fontSize='small' onClick={() => handleDeleteImage(index)} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary' onClick={handleCloseCreates}>
              Batal
            </Button>

            <Button onClick={handleSubmit} disabled={isSubmit || disabled}>
              {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(OperasionalCreate);
