import React, { memo, useState } from 'react';
import { DialogContent, Dialog, Grid, Typography, IconButton, TextField, makeStyles, Button, CircularProgress, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';

import { format, parseISO } from 'date-fns';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  }
});

interface Props {
  giro: GiroModalModel[];
  open: boolean;
  handleCloseCreate: () => void;
  handleAddGiro: (newGiro: GiroModalModel) => void;
}

const GiroModal = ({ giro, open, handleCloseCreate, handleAddGiro }: Props) => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [currentGiro, setCurrentGiro] = useState<GiroModalModel>({
    giroBank: '',
    giroNumber: '',
    giroDueDate: new Date(),
    giroAmount: 0
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target) {
      const { name, value } = event.target;
      setCurrentGiro(prev => {
        if (name === 'giroDueDate') {
          return { ...prev, giroDueDate: parseISO(value) };
        }
        return { ...prev, [name]: value };
      });
    }
  };

  const handleSubmit = () => {
    if (!currentGiro.giroBank || !currentGiro.giroAmount) {
      return;
    }
    setIsSubmit(true);
    handleAddGiro(currentGiro);
    setCurrentGiro({ giroBank: '', giroDueDate: new Date(), giroNumber: '', giroAmount: 0 });
    setIsSubmit(false);
    handleCloseCreate();
  };

  // Disable button jika data belum lengkap
  const disabled = !currentGiro.giroBank || !currentGiro.giroAmount;

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>Tambah Giro</Typography>
          <IconButton onClick={handleCloseCreate} size='small'>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row'>
          <Grid xs={12}>
            <TextField
              id='giroBank'
              name='giroBank'
              placeholder='cth. BRI, Mandiri'
              required
              fullWidth
              autoComplete='off'
              label='Giro Bank'
              value={currentGiro.giroBank}
              onChange={handleChange}
            />

            <TextField
              id='giroDueDate'
              name='giroDueDate'
              required
              fullWidth
              type='date'
              label='Tanggal Jatuh Tempo Giro'
              value={format(currentGiro.giroDueDate, 'yyyy-MM-dd')} // Format Date ke string
              onChange={handleChange}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id='giroNumber'
              name='giroNumber'
              required
              placeholder='cth. 123456789'
              fullWidth
              autoComplete='off'
              label='Nomor Giro'
              value={currentGiro.giroNumber}
              onChange={handleChange}
            />

            <TextField
              id='giroAmount'
              name='giroAmount'
              required
              fullWidth
              placeholder='Nominal giro'
              autoComplete='off'
              label='Jumlah'
              value={currentGiro.giroAmount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCurrentGiro(prev => ({ ...prev, giroAmount: +event.target.value }))}
              InputProps={{
                inputComponent: NumberFormatMask as any
              }}
            />
          </Grid>

          <Grid container item xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary' onClick={handleCloseCreate}>
              Batal
            </Button>
            <Button disabled={isSubmit || disabled} onClick={handleSubmit}>
              {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(GiroModal);
